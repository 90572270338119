<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <QR
      :url="linkurl"
      :title="title"
      ref="qr"
    />
    <v-card>
      <v-toolbar
        color="#f5f5f5"
      >
        <v-toolbar-title class="grey--text mx-auto">Direct Link</v-toolbar-title>
      </v-toolbar>
      <v-card-title class="grey--text"> URL</v-card-title>
      <v-card-text>
        <a :href="linkurl">{{ linkurl }}</a>
      </v-card-text>
      <v-card-text>
        <v-btn
          text
          @click="showQR"
        >
          show QR
        </v-btn>
      </v-card-text>
      
      <v-card-actions>
        <v-btn 
          text
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QR from '../QR.vue'
import { EncLst } from 'enclst-core-js'
export default {
  props: {
    enclst: {
      type: Object,
      default: new EncLst()
    },
/*
    url: {
      type: String,
      default: ""
    }
*/
  },
  components: {QR},
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    open(){
      this.dialog = true
    },
    close(){
      this.dialog = false
    },
    showQR(){
//      this.$refs.qr.open(this.enclst.filepath)
      this.$refs.qr.open()
    }
  },
  computed: {
    url(){
      return this.enclst.filepath
    },
    title(){
      return this.enclst.title
    },
    linkurl(){
      return 'https://encyclolist.uedasoft.com/list/' + encodeURIComponent(this.url)
    },
/*
    msg(){
      return (encodeURIComponent(this.linkurl))
    },
    qrurl(){
      return 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + this.msg
    }
*/
  }
}
</script>