<template>
  <v-list-item
    @click.right.prevent="openContextMenu"
    @click="$emit('selected', index)"
  >
    <QR
      :url="url"
      :title="title"
      ref="qr"
    />
    <v-list-item-content
    >
      <v-list-item-title v-text="item.title"></v-list-item-title>
    </v-list-item-content>
    <ul v-show="isContextMenuShown">
      <li><a @click.stop="showQR">show QR</a></li>
    </ul>
  </v-list-item>
</template>

<script>
import { Item } from 'enclst-core-js'
import QR from '@/dialogs/QR.vue'
export default {
  props: {
    index: Number,
    item: {
      type: Object,
      default: new Item()
    }
  },
  components: {QR},
  data: () => ({
    isContextMenuShown: false,
  }),
  methods:{
    openContextMenu(){
      this.isContextMenuShown = !this.isContextMenuShown
    },
    showQR(){
      this.$refs.qr.open()
    }
  },
  computed: {
    url(){
      return this.item.value.first()
    },
    title(){
      return this.item.title
    },
  }
}
</script>